import Image from "next/image"
import { useEffect, useState } from "react"
import { FaPlay } from "react-icons/fa"
import classNames from "classnames"

import { Video } from "./Video"
import { Sections } from "./Sections"
import { FAQ } from "../FAQ"
import { useMediaQuery } from "@chakra-ui/react"
import { ArticleJsonLd, CarouselJsonLd, NextSeo, OrganizationJsonLd, ProductJsonLd, FAQPageJsonLd } from "next-seo"

const seoProps = {
  title: "Innovation Brindes: Brindes Personalizados e Corporativos",
  description:
    "Brindes personalizados e corporativos que fazem a diferença. Soluções criativas para sua empresa. Design exclusivo e preços competitivos. Peça já!",
  canonical: "https://innovationbrindes.com.br",
  openGraph: {
    url: "https://innovationbrindes.com.br",
    title: "Innovation Brindes: Brindes Personalizados e Promocionais de Alta Qualidade",
    description:
      "Conheça a Innovation Brindes, a líder em brindes personalizados no Brasil. Descubra nossa vasta gama de produtos, incluindo brindes corporativos e ecológicos.",
    images: [
      {
        url: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20188%402x.png1721917419.5623",
        width: 800,
        height: 600,
        alt: "Imagem principal dos brindes",
      },
    ],
    site_name: "Innovation Brindes",
    type: "website",
    locale: "pt_BR",
  },
  twitter: {
    handle: "@innovationbrindes",
    site: "@innovationbrindes",
    cardType: "summary_large_image",
    title: "Innovation Brindes: Brindes Personalizados e Promocionais de Alta Qualidade",
    description:
      "Conheça a Innovation Brindes, a líder em brindes personalizados no Brasil. Descubra nossa vasta gama de produtos, incluindo brindes corporativos e ecológicos.",
    image: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20188%402x.png1721917419.5623",
  },
}

const organizationJsonLd = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Innovation Brindes",
  url: "https://innovationbrindes.com.br",
  logo: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/innovation-logo.png",
  sameAs: [
    "https://www.facebook.com/innovationbrindes",
    "https://www.instagram.com/innovationbrindes",
    "https://br.pinterest.com/innovationbrindes",
    "https://www.youtube.com/c/InnovationbrindesBr",
  ],
}

const articleJsonLd = {
  url: "https://innovationbrindes.com.br/",
  title: "Innovation Brindes: Brindes Personalizados e Promocionais de Alta Qualidade",
  images: ["https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20188%402x.png1721917419.5623"],
  datePublished: "2024-07-29T08:00:00+00:00",
  dateModified: "2024-07-29T09:00:00+00:00",
  authorName: "Innovation Brindes",
  publisherName: "Innovation Brindes",
  publisherLogo: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/innovation-logo.png",
  description:
    "A Innovation Brindes é a maior empresa de brindes personalizados promocionais do Brasil, com mais de 20 anos de experiência em brindes corporativos e promocionais.",
}

export default function Home({ reviews, produtos, faq, instagramPosts }) {
  const [videoOpen, setVideoOpen] = useState(false)
  const [isMobile] = useMediaQuery("(max-width: 768px)")

  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  function handleOpenVideo() {
    setVideoOpen(true)
  }

  function handleCloseVideo() {
    setVideoOpen(false)
  }

  return (
    <>
      <NextSeo {...seoProps} />
      <ArticleJsonLd {...articleJsonLd} />
      <OrganizationJsonLd {...organizationJsonLd} />
      {/* muitos products ld json */}
      <CarouselJsonLd
        ofType="default"
        data={produtos?.categorias.map((cat) => ({
          name: cat.titulo_categoria,
          image: [cat.imagem_categoria],
          description: cat.descricao_categoria,
          brand: "Innovation Brindes",
          url: `https://innovationbrindes.com.br/categoria/${cat.link_categoria}`,
        }))}
      />
      <ProductJsonLd
        productName={"Copo térmico inox 500ml C/ Tampa Personalizado"}
        images={[
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/11-11.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/10-10.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/9-9.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/8-8.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/7-7.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/6-6.jpg",
          "https://imgprodutos.s3.us-east-2.amazonaws.com/5049/5-5.jpg",
        ]}
        description={
          "Copo Térmico Inox 500ml com tampa e abridor. Disponível em cores exclusivas e acabamento de verniz. Material livre de BPA"
        }
        brand="Innovation Brindes"
        key={5049}
        slogan="Copo térmico inox 500ml C/ Tampa Personalizado"
        scriptId="productJsonLd"
        sku="5049"
        type="Product"
        offers={[
          {
            price: "105.77",
            priceCurrency: "BRL",
            priceValidUntil: new Date().toISOString().split("T")[0],
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock",
            url: "https://innovationbrindes.com.br/copo-termico-brilho-e-cores-especiais-brindes-personalizados-promocional-10151375049",
            seller: {
              name: "Innovation Brindes",
            },
          },
        ]}
      />
      <FAQPageJsonLd
        mainEntity={faq.map((item) => ({
          questionName: item.pergunta,
          acceptedAnswerText: item.resposta,
        }))}
      />

      <main className="container mx-auto mt-2 space-y-10 md:space-y-6 px-3 mb-10">
        <section
          className={classNames("flex relative transition-height", {
            "md:h-[403px]": !videoOpen,
            "md:h-[520px]": videoOpen,
            "items-center": videoOpen,
            "justify-center": videoOpen,
          })}
        >
          {mobile && (
            <div className="relative">
              {!videoOpen && (
                <>
                  <div className="mt-10">
                    <Image
                      src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/Flattened%20851%402x.png1721847584.3122"
                      alt="Innovation Brindes"
                      width={420}
                      height={317}
                      loading="eager"
                      priority
                      quality={60}
                      objectFit="cover"
                    />
                  </div>

                  <div className="absolute inset-0 flex flex-col items-center gap-9 px-4">
                    <h1 className="font-bold text-2xl text-left">Brindes Personalizados é na Innovation Brindes</h1>
                    <button
                      aria-label="Play video"
                      onClick={handleOpenVideo}
                      className="animate-scale-110 w-20 h-20 bg-[#EF511B] rounded-full flex items-center justify-center border-2 border-white"
                    >
                      <FaPlay className="text-white text-3xl" />
                    </button>

                    <h2 className="max-w-[157px] w-full text-sm mr-auto">
                      Conheça nossa estrutura e entenda por que a Innovation Brindes é muito mais do que personalização.
                    </h2>
                  </div>
                </>
              )}
              {videoOpen && (
                <div onClick={handleCloseVideo} className="w-full h-fit bg-black">
                  <Video
                    url="https://www.youtube.com/watch?v=6DAerB-5p3c"
                    width="100%"
                    height="100%"
                    muted
                    playing={videoOpen}
                    controls
                    onEnded={handleCloseVideo}
                  />
                </div>
              )}
            </div>
          )}
          {videoOpen && !mobile && (
            <div onClick={handleCloseVideo} className="w-[888px] h-[500px] bg-black">
              <Video
                url="https://www.youtube.com/watch?v=6DAerB-5p3c"
                width="100%"
                height="100%"
                muted
                playing={videoOpen}
                controls
                onEnded={handleCloseVideo}
              />
            </div>
          )}
          <div
            className={classNames("hidden md:block", {
              "md:hidden": videoOpen,
            })}
          >
            <div className="absolute right-0">
              <Image
                src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/woman-hero.png1721774212.2713"
                alt="Innovation Brindes"
                width={630}
                height={400}
                loading="eager"
                priority
                quality={10}
                objectFit="cover"
              />
            </div>
            <div className="absolute top-1/2 -translate-y-[60%] left-1/2 -translate-x-[35%] flex items-center">
              <Image
                src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/4.0.png1721670294.8013"
                alt="4.0"
                width={250}
                height={250}
                objectFit="contain"
              />

              <button
                aria-label="Play video"
                onClick={handleOpenVideo}
                className="absolute top-[30%] z-50 -right-5 animate-scale-110 w-20 h-20 bg-[#EF511B] rounded-full flex items-center justify-center border-2 border-white"
              >
                <FaPlay className="text-white text-3xl" />
              </button>
            </div>

            <div className="flex flex-col gap-6 max-w-[581px] w-full relative z-2 py-20">
              <h1 className="font-bold text-5xl">Brindes Personalizados é na Innovation Brindes</h1>
              <h2 className="text-2xl font-medium leading-relaxed">
                Conheça nossa estrutura e <br /> entenda por que a Innovation Brindes <br /> é muito mais do que
                personalização.
              </h2>
            </div>
          </div>
        </section>

        {/* por que escolher a innovation (cards) */}
        <Sections.PorQueEscolher />

        {/* NOSSAS CATEGORIAS (cards) */}

        <Sections.NossasCategorias productsCategory={produtos?.categorias} />

        {/* PERSONALIZAÇÃO EXPRESSA PARA SEUS BRINDES */}
        <Sections.PersonalizacaoExpressaExclusivo />

        {/* brindes para eventos */}

        <Sections.BrindesParaEventos productsEvents={produtos?.brindes_eventos} />

        {/* Facilidade e vantagens na forma de pagamento */}

        <Sections.FacilidadeVantagens />

        {/* SUGESTÕES PARA VOCÊ: DESCUBRA NOSSOS BRINDES PERSONALIZADOS MAIS VENDIDOS */}
        <Sections.Sugestoes />

        {/* Brindes promocionais em destaque */}

        <Sections.BrindesPromocionais productsPromocionais={produtos?.mais_vendidos} />

        {/* EXPLORE NOSSO BLOG DE BRINDES PERSONALIZADOS */}
        <Sections.ExploreBlog />

        {/* O quee os nosso clientes dizem */}

        <Sections.Reviews reviews={reviews} />

        {/* Depoimentos em destaque */}

        <Sections.DepoimentosDestaque reviews={reviews} />

        {/* SEU BRINDE PERSONALIZADO EM 5 PASSOS SIMPLES */}

        <Sections.Personalizado5Passos />

        {/* VOCÊ SÓ ENCONTRA AQUI */}

        <Sections.VoceSoEncontraAqui />

        {/* FAQ */}
        <FAQ faq={faq} />

        {/* Saiba mais */}

        <Sections.SaibaMais />

        <Sections.OndeEstamos />

        <Sections.InstagramPosts posts={instagramPosts?.data} />
      </main>
    </>
  )
}
