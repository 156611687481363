import { HeadingSection } from "../HeadingSection"
import { FaPencilAlt, FaCalendarAlt, FaTruck } from "react-icons/fa"
import { AiFillLike } from "react-icons/ai"
import { BsGearFill } from "react-icons/bs"
import { Personalizado5PassosSlide } from "./Personalizado5PassosSlide"

export function Personalizado5Passos() {
  return (
    <section className="flex flex-col gap-6">
      <HeadingSection text="SEU BRINDE PERSONALIZADO EM 5 PASSOS SIMPLES" />

      <div className="hidden md:flex w-full py-6 px-3 items-start gap-16 border flex-wrap">
        <div className="flex flex-col gap-4 max-w-[510px] w-full">
          <span className="font-bold text-2xl border-b pb-6 px-3">O que fazer</span>

          <div className="flex flex-col gap-4">
            <div className="h-[68px] flex items-center font-bold px-3 justify-between">
              1. Solicite um Orçamento Online
              <div className="h-9 w-9 bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                <BsGearFill className="text-2xl" />
              </div>
            </div>
            <div className="h-[68px] flex items-center font-bold px-3 justify-between bg-[#F5F5F5]">
              2. Contato com o Consultor de Vendas e Desenvolvimento do Layout Virtual{" "}
              <div className="h-9 max-w-[36px] w-full bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                <FaPencilAlt className="text-2xl" />
              </div>
            </div>
            <div className="h-[68px] flex items-center font-bold px-3 justify-between">
              3. Aprovação e Pagamento
              <div className="h-9 max-w-[36px] w-full bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                <AiFillLike className="text-2xl" />
              </div>
            </div>
            <div className="h-[68px] flex items-center font-bold px-3 justify-between bg-[#F5F5F5]">
              4. Defina o Prazo de Produção
              <div className="h-9 max-w-[36px] w-full bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                <FaCalendarAlt className="text-2xl" />
              </div>
            </div>
            <div className="h-[68px] flex items-center font-bold px-3 justify-between">
              5. Acompanhe Seu Pedido
              <div className="h-9 max-w-[36px] w-full bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                <FaTruck className="text-2xl" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 flex-1">
          <span className="font-bold text-2xl border-b pb-6 px-3">Como fazer</span>

          <div className="flex flex-col gap-4">
            <div className="h-[68px] flex items-center font-medium px-3">
              Faça um orçamento diretamente pelo nosso site, selecionando e configurando o produto conforme sua
              necessidade.
            </div>
            <div className="h-[68px] flex items-center font-medium px-3 bg-[#F5F5F5]">
              Nosso consultor entrará em contato para discutir detalhes e criar uma amostra virtual personalizada.
            </div>
            <div className="h-[68px] flex items-center font-medium px-3">
              Após a revisão e aprovação da amostra, proceda com o pagamento, aproveitando diversas formas de pagamento.
            </div>
            <div className="h-[68px] flex items-center font-medium px-3 bg-[#F5F5F5]">
              Prazo de produção conforme necessidade: melhor custo-benefício em 10 dias úteis ou produção em até 48
              horas.
            </div>
            <div className="h-[68px] flex items-center font-medium px-3">
              Receba um código de rastreamento e atualizações via WhatsApp e e-mail para acompanhar o andamento do
              pedido.
            </div>
          </div>
        </div>
      </div>

      <Personalizado5PassosSlide />
    </section>
  )
}
