import { FaDollarSign, FaTruck, FaCreditCard, FaCheck } from "react-icons/fa"
import { CardBeneficios } from "../CardBeneficios"
import { HeadingSection } from "../HeadingSection"

export function PorQueEscolher() {
  return (
    <section className="flex flex-col gap-6 w-full">
      <HeadingSection text="Por que escolher a Innovation Brindes?" />
      <div className="grid grid-cols-2 md:flex items-center justify-between flex-row flex-wrap gap-2">
        <CardBeneficios.Root>
          <CardBeneficios.Header>
            <h2 className="text-sm md:text-base">Frete Grátis</h2>
            <CardBeneficios.Icon>
              <FaTruck className="text-white text-2xl" />
            </CardBeneficios.Icon>
          </CardBeneficios.Header>

          <CardBeneficios.Content>
            <h3 className="text-xs md:text-sm inline">
              Frete grátis para brindes personalizados{" "}
              <h3 className="font-bold text-xs md:text-sm inline">no Sul e Sudeste</h3>
            </h3>
          </CardBeneficios.Content>
        </CardBeneficios.Root>

        <CardBeneficios.Root>
          <CardBeneficios.Header>
            <h2 className="text-sm md:text-base">5% De Desconto</h2>
            <CardBeneficios.Icon>
              <FaDollarSign className="text-white text-2xl" />
            </CardBeneficios.Icon>
          </CardBeneficios.Header>

          <CardBeneficios.Content>
            <h3 className="text-sm inline">
              Desconto em brindes personalizados <span className="font-bold text-sm inline"> pagando com PIX</span>
            </h3>
          </CardBeneficios.Content>
        </CardBeneficios.Root>

        <CardBeneficios.Root>
          <CardBeneficios.Header>
            <h2 className="text-sm md:text-base">10X Sem Juros</h2>
            <CardBeneficios.Icon>
              <FaCreditCard className="text-white text-2xl" />
            </CardBeneficios.Icon>
          </CardBeneficios.Header>

          <CardBeneficios.Content>
            Brindes personalizados em até <h3 className="font-bold text-sm inline">10x sem juros no cartão</h3>
          </CardBeneficios.Content>
        </CardBeneficios.Root>

        <CardBeneficios.Root>
          <CardBeneficios.Header>
            <h2 className="text-sm md:text-base">Pronto em 48 Horas:</h2>
            <CardBeneficios.Icon>
              <FaCheck className="text-white text-2xl" />
            </CardBeneficios.Icon>
          </CardBeneficios.Header>

          <CardBeneficios.Content>
            <h3 className="text-xs md:text-sm inline">
              Brindes personalizados <span className="font-bold text-sm inline">rápidos e eficientes.</span>
            </h3>
          </CardBeneficios.Content>
        </CardBeneficios.Root>
      </div>
    </section>
  )
}
