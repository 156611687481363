import { HeadingSection } from "../HeadingSection"
import { DepoimentosDestaqueSlide } from "./DepoimentosDestaqueSlide"
import { CommentCardGoogle } from "../CommentCardGoogle"
import React from "react"
import dynamic from "next/dynamic"

const ModalDepoimentos = dynamic(() => import("../ModalDepoimentos").then((mod) => mod.ModalDepoimentos), {
  ssr: false,
})

export function DepoimentosDestaque({ reviews }) {
  return (
    <section className="flex flex-col gap-6">
      <HeadingSection text="DEPOIMENTOS EM DESTAQUE" />

      <div className="hidden md:grid md:grid-cols-3 gap-9 place-items-center">
        {reviews?.data?.map((review, index) => (
          <CommentCardGoogle key={Math.random().toString(36).substring(7)} review={review} />
        ))}
      </div>

      <DepoimentosDestaqueSlide items={reviews?.data} />
      <ModalDepoimentos />
    </section>
  )
}
