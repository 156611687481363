import React, { useEffect, useState } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { useMediaQuery } from "@chakra-ui/react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { CardSugestoes } from "../CardSugestoes"

export function SugestoesSlide({ sugestoesItems }) {
  const [mobile, setMobile] = useState(false)

  const [isMobile] = useMediaQuery("(max-width: 1023px)")

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  if (!mobile) {
    return null
  }

  return (
    <CarouselProvider
      naturalSlideWidth={500}
      naturalSlideHeight={500}
      totalSlides={sugestoesItems.length}
      step={1}
      visibleSlides={mobile ? 1 : 3}
      isIntrinsicHeight={true}
      isPlaying
      infinite={true}
      className="relative"
    >
      <Slider>
        {sugestoesItems.map((item) => (
          <Slide>
            <CardSugestoes item={item} key={item.title} />
          </Slide>
        ))}
      </Slider>

      <>
        <ButtonBack className="absolute left-0 border top-[40%] transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
          <IoIosArrowBack className="text-2xl" />
        </ButtonBack>
        <ButtonNext className="absolute right-0 border top-[40%] transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
          <IoIosArrowForward className="text-2xl" />
        </ButtonNext>
      </>
    </CarouselProvider>
  )
}
