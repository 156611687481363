import Image from "next/image"
import { HeadingSection } from "../HeadingSection"
import Link from "next/link"
import { useMediaQuery } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FaPlay } from "react-icons/fa"
import { VideoExclusivo } from "./VideoExclusivo"

export function PersonalizacaoExpressaExclusivo() {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  return (
    <section className="flex justify-between flex-col md:flex-row gap-10 md:gap-4">
      <div className="flex flex-col gap-6 flex-1 max-w-[616px] w-full">
        <HeadingSection text="PERSONALIZAÇÃO EXPRESSA PARA SEUS BRINDES" />

        <div className="flex items-center">
          <div className="flex items-center justify-center md:block max-w-[616px] w-full h-full md:h-[388px] relative hover:-translate-y-1 md:hover:shadow-xl transition">
            <Image
              src={
                !mobile
                  ? "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20196%402x.png1722096399.4697"
                  : "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20204%402x.png1722270152.0105"
              }
              alt="Retire seu brinde personalizado no mesmo dia"
              width={!mobile ? 616 : 364}
              height={!mobile ? 388 : 578}
              objectFit="contain"
            />

            <div className="max-w-[276px] h-[232px] justify-between w-full flex flex-col gap-2 absolute bottom-3 md:bottom-6 md:left-6 bg-white/80 py-2 px-4 rounded-md">
              <span className="text-xl font-bold">
                Brindes <br /> Personalizados <br /> Prontos em 48 Horas
              </span>
              <span className="text-sm font-medium">
                Compre agora e tenha seus brindes personalizados prontos em até 48 horas. Rápido, fácil e ágil!
              </span>

              <Link href="/ultra-rapido">
                <span className="font-medium py-2 rounded-xl bg-[#F45100] text-center text-white cursor-pointer">
                  Nossos rapidinhos!
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 flex-1 max-w-[616px] w-full relative">
        <HeadingSection text="Exclusivo: Impressão UV 360º" />

        <VideoExclusivo mobile={mobile} />
      </div>
    </section>
  )
}
