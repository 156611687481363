import styled from "styled-components"

const Slider = styled.div`
  max-width: 357px;
  width: 100%;
  height: 8px;
  border-radius: 7px;
  background: ${({ percentage }) => `linear-gradient(90deg, #FABB05 ${percentage}%, #CFCFCF ${percentage}%)`};
  position: relative;

  &:hover {
    &::after {
      content: attr(data-quantity);
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 10px;
      background: #000;
      color: #fff;
      border-radius: 5px;
      font-size: 12px;
    }
  }
`

export const ReviewComponent = {}

ReviewComponent.Root = function ReviewComponentRoot({ children }) {
  return <div className="flex items-center gap-2">{children}</div>
}

ReviewComponent.Quantity = function ReviewComponentHeader({ children }) {
  return <span className="font-bold">{children}</span>
}

ReviewComponent.Slider = function ReviewComponentSlider({ quantity, total }) {
  return <Slider data-quantity={`${quantity} comentários`} percentage={(quantity / total) * 100} />
}
