import Link from "next/link"
import { HeadingSection } from "../HeadingSection"

export function SaibaMais() {
  return (
    <section className="flex flex-col gap-6">
      <HeadingSection text="SAIBA MAIS SOBRE A INNOVATION BRINDES" />

      <div className="flex flex-col gap-6 text-sm">
        <h2 className="font-bold">Innovation Brindes: Líder em Brindes Personalizados no Brasil</h2>
        <p>
          Na Innovation Brindes, lideramos o mercado de <strong>brindes personalizados</strong>, oferecendo mais de
          4.000 itens a pronta entrega, abrangendo uma variedade sem igual de produtos promocionais de alta qualidade e{" "}
          <strong>preços competitivos. </strong>
          Como pioneiros em soluções promocionais, garantimos a representação perfeita da sua marca, com produtos que
          vão desde{" "}
          <Link href="/categoria/caneta-plastica-brindes-personalizados/1023103">
            <a
              href="/categoria/caneta-plastica-brindes-personalizados/1023103"
              className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
            >
              canetas promocionais,{" "}
            </a>
          </Link>
          <Link href="/categoria/mochilas-personalizadas-para-brindes/1032146">
            <a
              href="/categoria/mochilas-personalizadas-para-brindes/1032146"
              className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
            >
              mochilas customizadas,{" "}
            </a>
          </Link>
          <Link href="/categoria/mala-de-viagem-personalizada-para-brindes/1032144">
            <a
              href="/categoria/mala-de-viagem-personalizada-para-brindes/1032144"
              className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
            >
              malas de bordo exclusivas,{" "}
            </a>
          </Link>
          <Link href="/categoria/pen-drive-personalizado-para-brindes/1012132">
            <a
              href="/categoria/pen-drive-personalizado-para-brindes/1012132"
              className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
            >
              pen drives{" "}
            </a>
          </Link>
          até{" "}
          <Link href="/categoria/squeeze-personalizado-para-brindes/1015092">
            <a
              href="/categoria/squeeze-personalizado-para-brindes/1015092"
              className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
            >
              squeezes personalizados
            </a>
          </Link>
          , ideais para qualquer evento corporativo.
        </p>
        <h2 className="font-bold">Vantagens Exclusivas da Innovation Brindes:</h2>
        <ul className="flex flex-col gap-6 list-disc pl-5">
          <li>
            <h3 className="font-bold inline">Produção Rápida e Personalizada: </h3>
            <p className="inline">
              Nos destacamos pelo melhor prazo de produção do mercado, com a capacidade de entregar brindes customizados
              em até{" "}
              <Link href="/ultra-rapido">
                <a
                  href="/ultra-rapido"
                  className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
                >
                  48 horas
                </a>
              </Link>
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">Amostra Virtual Grátis: </h3>{" "}
            <p className="inline">
              Veja como sua marca vai ficar aplicada no seu produto em apenas 60 minutos e totalmente grátis.
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">Frete Grátis:</h3>{" "}
            <p className="inline">
              Oferecemos frete grátis para as regiões Sul e Sudeste, incluindo os estados de Paraná (PR), Santa Catarina
              (SC), Rio Grande do Sul (RS), São Paulo (SP), Rio de Janeiro (RJ), Minas Gerais (MG) e Espírito Santo
              (ES). Para outros estados, temos <strong>fretes promocionais</strong> disponíveis.
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">
              Brindes Personalizados a Partir de Uma Unidade e em Grandes Quantidades:{" "}
            </h3>
            <p className="inline">
              Oferecemos a flexibilidade de personalizar brindes a partir de apenas uma unidade e também atendemos
              grandes pedidos de até 1.000 unidades ou mais, sempre ao <strong>melhor preço.</strong>
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">Condições de Pagamento Flexíveis: </h3>
            <p className="inline">
              Facilitamos suas compras com 5% de desconto no pagamento via PIX e a opção de parcelamento em até 12x sem
              juros no cartão de crédito. Além disso, faturamos para pessoa jurídica mediante aprovação de análise de
              crédito.
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">Tracking do Seu Pedido: </h3>

            <p className="inline">
              Com a Innovation Brindes, você acompanha cada etapa do seu pedido. Enviamos atualizações por e-mail e
              mensagens via WhatsApp, mantendo você sempre informado.
            </p>
          </li>
          <li>
            <h3 className="font-bold inline">Logística Nacional: </h3>
            <p className="inline">
              Contamos com uma logística robusta capaz de realizar entregas para todas as cidades do Brasil, garantindo
              rapidez e eficiência na distribuição de seus brindes.
            </p>
          </li>
        </ul>

        <section>
          <h2 className="font-bold">Exclusividade Innovation Brindes:</h2>
          <p>
            Tenha a sua loja de brindes exclusiva, ideal para grandes operações ou franquias, com mais de 4.000 itens
            disponíveis. Graças ao nosso time de TI interno, podemos oferecer soluções personalizadas que se adaptam
            perfeitamente às suas necessidades, maximizando sua eficácia promocional e garantindo uma gestão de marca
            consistente.
          </p>
        </section>

        <section className="space-y-2">
          <h2 className="font-bold">Tipos de Personalizações Disponíveis:</h2>
          <ul className="flex flex-col gap-2 list-disc pl-5">
            <li>DTF</li>
            <li>Gravação a Laser</li>
            <li>Tampografia</li>
            <li>UV Localizado</li>
            <li>UV Total</li>
            <li>Impressão UV 360º</li>
          </ul>
        </section>

        <section>
          <h2 className="font-bold">Compromisso com a Qualidade e Inovação</h2>
          <p>
            Na Innovation Brindes, sua marca ganha destaque com impressões de alta qualidade, assegurando uma imagem
            profissional e impactante. Nossa equipe de mais de 30 consultores especializados está pronta para oferecer
            um atendimento personalizado, entendendo profundamente suas necessidades e prazos
          </p>
        </section>

        <section>
          <h2 className="font-bold">Descubra as Últimas Tendências em Brindes Promocionais</h2>
          <p>
            Explore nossa vasta gama de produtos e fique por dentro das últimas novidades do mercado de brindes
            personalizados. Seja para{" "}
            <Link href="/categoria/acessorios-personalizados-para-brindes/1012055">
              <a
                href="/categoria/acessorios-personalizados-para-brindes/1012055"
                className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
              >
                brindes tecnológicos
              </a>
            </Link>
            , <strong>brindes promocionais</strong> ou brindes corporativos{" "}
            <Link href="/segmento/corporativo/14">
              <a
                href="/segmento/corporativo/14"
                className="underline cursor-pointer text-black font-semibold hover:text-[#F45100]"
              >
                brindes corporativos
              </a>
            </Link>
            , a Innovation Brindes é sua parceira ideal em soluções criativas e eficazes.
          </p>
        </section>

        <section>
          <p>
            Escolha a Innovation Brindes para estar sempre à frente da concorrência com brindes que capturam a atenção e
            fidelizam seu público-alvo. <span className="font-bold">Contate-nos</span> hoje mesmo e transforme sua
            estratégia promocional com os líderes do mercado!
          </p>
        </section>
      </div>
    </section>
  )
}
