export const CardBeneficios = {}

CardBeneficios.Root = function CardBeneficiosRoot({ children }) {
  return (
    <div className="md:max-w-[276px] w-full h-[113px] shadow-md border relative hover:-translate-y-1 hover:shadow-xl transition">
      {children}
    </div>
  )
}

CardBeneficios.Header = function CardBeneficiosTitle({ children, Icon }) {
  return (
    <div className="bg-chess-grid relative h-1/3 w-full">
      <div className="w-full px-2 font-bold text-xl flex items-center justify-between absolute top-0 bg-gradient-to-r from-[#B7DA05] to-[#B7DA05]/40 h-full">
        {children}
      </div>
    </div>
  )
}

CardBeneficios.Icon = function CardBeneficiosContent({ children }) {
  return <div className="w-8 h-8 rounded-full bg-[#0351F1] flex items-center justify-center">{children}</div>
}

CardBeneficios.Content = function CardBeneficiosContent({ children }) {
  return <div className="px-2 md:py-3 text-black">{children}</div>
}
