import dynamic from "next/dynamic"
import { HeadingSection } from "../HeadingSection"
import React, { useRef } from "react"

const locations = [
  {
    name: "Innovation Brindes 1",
    description: "Rua Alesso Baldovinetti, 378 - Casa Verde Alta, São Paulo - SP, 02555-000",
    location: {
      lat: -23.492025,
      lng: -46.6669882,
    },
  },
]

const Map = dynamic(() => import("../../Map").then((mod) => mod.Map), {
  loading: () => <p>Carregando mapa...</p>,
  ssr: false,
})

export function OndeEstamos() {
  const containerMapRef = useRef(null)
  const [mapVisible, setMapVisible] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setMapVisible(true)
      }
    })

    observer.observe(containerMapRef.current)

    return () => observer.disconnect()
  }, [])

  return (
    <>
      <HeadingSection text="Onde estamos" />

      <div ref={containerMapRef} className="h-fit w-full">
        {mapVisible && <Map locations={locations} />}
      </div>
    </>
  )
}
