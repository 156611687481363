import { FcGoogle } from "react-icons/fc"
import { StarReviews } from "../StarReviews"
import Image from "next/image"

export function CommentCardGoogle({ review }) {
  return (
    <div className="max-w-[360px] mx-auto w-full h-[220px] rounded-md shadow-md hover:shadow-lg border px-3 py-3 relative overflow-auto no-scrollbar">
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-2">
          <div className="w-[48px]">
            {/* <Image src={review.photo} alt={review.name} quality={100} width={48} height={48} objectFit="fill" /> */}
            <Image
              key={review.name}
              width={48}
              height={48}
              src={review.photo}
              alt={review.name}
              className="rounded-full"
              unoptimized
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-medium">{review.name}</span>
            <StarReviews rating={review.rating} className="text-sm" />
            <span className="text-xs mt-1">{review.reviewDate}</span>
          </div>
        </div>
        <FcGoogle className="text-4xl" />
      </div>

      <div className="h-32 w-1 absolute left-4 bottom-2 rounded-2xl overflow-hidden">
        <div className="h-2/3 bg-[#D9D9D9] rounded-2xl"></div>
        <div className="h-1/3 bg-[#707070] rounded-2xl"></div>
      </div>

      <p className="text-xs font-medium ml-12 mt-5 leading-relaxed overflow-auto">"{review.description}"</p>
    </div>
  )
}
