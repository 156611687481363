import dynamic from "next/dynamic"
import { HeadingSection } from "../HeadingSection"

const Diferencas = dynamic(() => import("../../Diferencas").then((mod) => mod.Diferencas), { ssr: false })

export function VoceSoEncontraAqui() {
  return (
    <section className="flex flex-col md:gap-6 gap-4">
      <HeadingSection text="VOCÊ SÓ ENCONTRA AQUI" />
      <div className="w-full h-full md:bg-[#F5F5F5] relative md:mt-10">
        <div className="md:-translate-y-10">
          <Diferencas />
        </div>
      </div>
    </section>
  )
}
