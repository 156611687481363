import { HeadingSection } from "../HeadingSection"
import { useEffect, useState } from "react"
import { useMediaQuery } from "@chakra-ui/react"
import { SugestoesSlide } from "./SugestoesSlide"
import { CardSugestoes } from "../CardSugestoes"

const sugestoesItems = [
  {
    urlImage: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Mask%20Group%2027%402x.png1722270749.6259",
    title: "Mochilas Personalizadas Promocionais",
    description:
      "Mochilas personalizadas com design moderno e durável, disponíveis em várias opções de personalização.",
    link: "/categoria/mochilas-personalizadas-para-brindes/1032146",
  },
  {
    urlImage: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Mask%20Group%2028%402x.png1721739225.314",
    title: "Explore Nossos Kits de Churrasco",
    description:
      "Kits de churrasco personalizados, ideais para eventos e confraternizações. Alta qualidade e personalização exclusiva.",
    link: "/categoria/kit-churrasco-personalizado-para-brindes/1020073",
  },
  {
    urlImage: "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Mask%20Group%2029%402x.png1722096195.9784",
    title: "Descubra o Copo Térmico 500ml",
    description:
      "Copo térmico de 500ml em inox, com parede dupla e tampa acrílica. Perfeito para manter bebidas na temperatura ideal.",
    link: "/copo-termico-500ml-com-abridor-brindes-10151373687",
  },
]

export function Sugestoes() {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])
  return (
    <section className="flex flex-col gap-6">
      <HeadingSection text="Sugestões para você: descubra nossos brindes personalizados mais vendidos" />
      {!mobile && (
        <div className="flex items-center justify-between flex-row flex-wrap">
          {sugestoesItems.map((item) => (
            <CardSugestoes item={item} key={item.title} />
          ))}
        </div>
      )}

      <SugestoesSlide sugestoesItems={sugestoesItems} />
    </section>
  )
}
