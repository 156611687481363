import Image from "next/image"
import { HeadingSection } from "../HeadingSection"
import { useMediaQuery } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export function FacilidadeVantagens() {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  return (
    <section className="flex gap-6 flex-col">
      <HeadingSection text="Facilidade e vantagens na forma de pagamento" />

      <div className="w-full h-[578px] md:h-[195px] bg-gray-100 md:shadow-md md:border relative overflow-hidden">
        <div className="absolute top-0 left-[-75%] z-20 block w-[50%] h-full bg-shine-gradient skew-x animate-shine" />
        <Image
          src={
            !mobile
              ? "https://imgproductioncrm.s3.us-east-2.amazonaws.com/pagamento-desktop.jpg1722284147.6129"
              : "https://imgproductioncrm.s3.us-east-2.amazonaws.com/pagamento-mobile.jpg1722284160.8771"
          }
          alt="5x sem juros no cartão de crédito e 5% de desconto no Pix"
          layout="fill"
          objectFit={!mobile ? "fill" : "fill"}
        />

        <div className="hidden md:block max-w-[205px] w-full text-left absolute left-7 bottom-7">
          <strong>Pague com Pix</strong> e <strong>ganhe 5% de desconto</strong> ou divida em até{" "}
          <strong>10x sem juros</strong> no cartão de crédito.
        </div>

        {mobile && (
          <div className="max-w-[276px] py-2  items-center justify-between w-full flex flex-col gap-4 absolute bottom-3 left-1/2 -translate-x-1/2 bg-white/80 px-4 rounded-md">
            <span className="text-lg font-bold">
              Brindes personalizados com condições de pagamento flexíveis para você
            </span>

            <div className="flex items-center gap-2">
              <span className="text-sm">
                Pague com Pix e ganhe 5% de desconto ou divida em até 12x sem juros no cartão de crédito.
              </span>
            </div>

            {/* <Link href="/ultra-rapido">
              <span className="font-medium py-2 px-2 rounded-xl bg-[#F45100] text-center text-white cursor-pointer block">
                Compre e Retire Hoje
              </span>
            </Link> */}
          </div>
        )}
      </div>
    </section>
  )
}
