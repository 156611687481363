import Image from "next/image"
import Link from "next/link"
import { FaArrowRight } from "react-icons/fa"

export function CardSugestoes({ item }) {
  return (
    <Link href={item.link}>
      <a
        href={item.link}
        className="flex flex-col gap-6 w-full md:max-w-[397px] hover:-translate-y-1 transition group cursor-pointer"
      >
        <div className="w-full h-[391px] shadow-md border relative overflow-hidden">
          {/* <div class="absolute top-0 left-[-75%] z-20 block w-[50%] h-full bg-shine-gradient skew-x animate-shine" /> */}
          <Image src={item.urlImage} alt={item.title} width={500} height={500} objectFit="cover" />
        </div>
        <Link href="/brindes">
          <span className="font-bold flex items-center gap-2">
            {item.title}
            <FaArrowRight className="group-hover:translate-x-5 transition" />
          </span>
        </Link>

        <p className="text-sm font-medium">{item.description}</p>
      </a>
    </Link>
  )
}
