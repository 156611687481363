import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react"

export function FAQ({ faq }) {
  return (
    <div className="mx-auto mb-5 flex flex-col gap-9 px-4 md:px-0 w-full">
      <h2 className="text-[26px] font-bold text-left">Perguntas Frequentes (FAQs)</h2>
      <Accordion>
        {faq?.map((item, index) => (
          <AccordionItem key={index} border="none" borderBottom="1px solid">
            <h2>
              <AccordionButton _focus={{ boxShadow: "none" }} py={4}>
                <span className="flex-1 text-left text-lg font-semibold">
                  {index + 1}. {item.pergunta}
                </span>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p dangerouslySetInnerHTML={{ __html: item.resposta }} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
