import { useMediaQuery } from "@chakra-ui/react"
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel"
import { useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

import { BsGearFill } from "react-icons/bs"
import { PiHandSwipeRight } from "react-icons/pi"
import { FaCalendarAlt, FaPencilAlt, FaTruck } from "react-icons/fa"
import { AiFillLike } from "react-icons/ai"

const items = [
  {
    title: "1. Solicite um Orçamento Online",
    icon: <BsGearFill className="text-2xl" />,
    description:
      "Faça um orçamento diretamente pelo nosso site, selecionando e configurando o produto conforme sua necessidade.",
  },
  {
    title: "2. Contato com o Consultor de Vendas e Desenvolvimento do Layout Virtual",
    icon: <FaPencilAlt className="text-2xl" />,
    description: "Nosso consultor entrará em contato para discutir detalhes e criar uma amostra virtual personalizada.",
  },
  {
    title: "3. Aprovação e Pagamento",
    icon: <AiFillLike className="text-2xl" />,
    description:
      "Após a aprovação do layout, você receberá um link para efetuar o pagamento de forma segura e prática.",
  },
  {
    title: "4. Defina o Prazo de Produção",
    icon: <FaCalendarAlt className="text-2xl" />,
    description: "Após a confirmação do pagamento, você poderá definir o prazo de produção do seu pedido.",
  },
  {
    title: "5. Acompanhe Seu Pedido",
    icon: <FaTruck className="text-2xl" />,
    description: "Acompanhe o status do seu pedido em tempo real e receba notificações sobre cada etapa da produção.",
  },
]

export function Personalizado5PassosSlide() {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  if (!mobile) {
    return null
  }

  return (
    <CarouselProvider
      naturalSlideWidth={397}
      naturalSlideHeight={391}
      totalSlides={items.length}
      visibleSlides={1}
      isIntrinsicHeight={true}
      className="relative"
    >
      <Slider className="py-2">
        {items.map((item, index) => (
          <Slide key={index} index={index} className="px-12">
            <div className="w-full px-3 py-3 flex flex-col gap-9 border shadow-sm">
              <span className="font-bold text-2xl">O que fazer</span>

              <div className="flex justify-between items-start w-full">
                <span className="flex items-center font-bold max-w-[240px]">{item.title}</span>
                <div className="h-9 w-9 bg-[#F45100] rounded-full flex items-center justify-center text-white font-bold">
                  {item.icon}
                </div>
              </div>

              <span className="font-bold text-2xl">Como fazer</span>

              <div className="h-[68px] flex items-center font-medium">{item.description}</div>

              <div className="flex items-center justify-center gap-2 mt-auto">
                <span className="text-sm font-bold">Confira os próximos passos</span>{" "}
                <PiHandSwipeRight className="text-2xl" />
              </div>
            </div>
          </Slide>
        ))}
      </Slider>

      <ButtonBack className="absolute left-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
        <IoIosArrowBack className="text-2xl" />
      </ButtonBack>
      <ButtonNext className="absolute right-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
        <IoIosArrowForward className="text-2xl" />
      </ButtonNext>
    </CarouselProvider>
  )
}
