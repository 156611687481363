import { useMediaQuery } from "@chakra-ui/react"
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel"
import { useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { CommentCardGoogle } from "../CommentCardGoogle"

export function DepoimentosDestaqueSlide({ items }) {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  if (!mobile) {
    return null
  }

  return (
    <CarouselProvider
      naturalSlideWidth={397}
      naturalSlideHeight={391}
      totalSlides={items?.length}
      visibleSlides={1}
      isPlaying
      isIntrinsicHeight={true}
      infinite={true}
      className="relative"
    >
      <Slider className="py-2">
        {items?.map((item, index) => (
          <Slide key={index} index={index}>
            <CommentCardGoogle key={index} review={item} />
          </Slide>
        ))}
      </Slider>

      <ButtonBack className="absolute left-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
        <IoIosArrowBack className="text-2xl" />
      </ButtonBack>
      <ButtonNext className="absolute right-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
        <IoIosArrowForward className="text-2xl" />
      </ButtonNext>
    </CarouselProvider>
  )
}
