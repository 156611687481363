import React, { useEffect } from "react"
import { HeadingSection } from "../HeadingSection"
import { CardInstagram } from "../../CardInstagram"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { useMediaQuery } from "@chakra-ui/react"
import Image from "next/image"

export const InstagramPosts = ({ posts }) => {
  const [visibleSlides, setVisibleSlides] = React.useState(3)
  const [isDesktop] = useMediaQuery("(min-width: 1024px)")
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [isTablet] = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    setVisibleSlides(isDesktop ? 4 : isTablet ? 2 : isMobile ? 1 : 3)
  }, [isDesktop, isTablet, isMobile])

  return (
    <section aria-labelledby="instagram-posts" className="flex flex-col gap-4">
      <HeadingSection text="ACOMPANHE A INNOVATION BRINDES NO INSTAGRAM!" />
      <div className="w-full h-full md:bg-[#F5F5F5] relative md:mt-10">
        <div className="md:-translate-y-10">
          <CarouselProvider
            naturalSlideWidth={397}
            naturalSlideHeight={391}
            totalSlides={posts?.length}
            visibleSlides={visibleSlides}
            isPlaying
            isIntrinsicHeight={true}
            infinite={true}
            className="relative"
            lockOnWindowScroll={true}
          >
            <Slider className="py-2">
              {posts.map((post) => (
                <Slide key={post.id} index={post.id}>
                  <CardInstagram.Root>
                    <CardInstagram.Header username={post.username} timestamp={post.timestamp} />

                    <figure className="flex flex-col gap-2">
                      {post?.media_type === "VIDEO" ? (
                        <CardInstagram.Video src={post.media_url} thumbnail_url={post.thumbnail_url} />
                      ) : (
                        <CardInstagram.Image
                          caption={post.caption}
                          thumbnail_url={post.thumbnail_url}
                          media_url={post.media_url}
                        />
                      )}
                      <CardInstagram.VerMais link={post.permalink} />
                      <div className="flex items-center gap-2">
                        {post?.likes && <CardInstagram.Likes likes={post.likes} />}
                        {post?.comments && <CardInstagram.Comments comments={post.comments} />}
                      </div>
                      <CardInstagram.Content caption={post.caption} />
                    </figure>
                  </CardInstagram.Root>
                </Slide>
              ))}
            </Slider>

            <ButtonBack className="absolute left-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
              <IoIosArrowBack className="text-2xl" />
            </ButtonBack>
            <ButtonNext className="absolute right-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
              <IoIosArrowForward className="text-2xl" />
            </ButtonNext>
          </CarouselProvider>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-3 justify-end mb-4 mr-4 md:-translate-y-4 mt-4 md:mt-0">
          <div className="relative max-w-[230px] w-full flex items-center">
            <div className="relative w-full flex items-center">
              <Image
                src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/carrousel-de-seguidores-instagram.jpg1725370707.1191"
                alt="Carrousel de seguidores no Instagram"
                objectFit="cover"
                width={230}
                height={70}
              />
            </div>
          </div>

          <span className="font-bold text-sm">+ 5k seguidores</span>

          <a
            className="block max-w-[200px] w-full"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/innovationbrindes/"
          >
            <button
              className="bg-[#F45100] w-full py-1 rounded-full text-white"
              aria-label="Siga a Innovation Brindes no Instagram"
            >
              Siga Nossa Página
            </button>
          </a>
        </div>
      </div>
    </section>
  )
}
