import React from "react"
import Home from "../components/Home"
import { getCategorias, getSegmentos } from "../utils/getLinksHeader"
import { AcceptCookies } from "../components/AcceptCookies"
import { api } from "../services/api"
import Head from "next/head"
import dynamic from "next/dynamic"

const Header = dynamic(() => import("../components/Header"))

async function getHomeInfo() {
  const [reviews, products, faq, allReviews, instagramPosts] = await Promise.all([
    api.get("/crm-google-reviews/listar?page=1&limit=6"),
    api.get("/site/v2/home-site/listar-dados-home"),
    api.get("faq/listar-perguntas-produtos"),
    api.get("/crm-google-reviews/listar?page=1&limit=1000"),
    api.get("/meta/instagram/listagem"),
  ])

  if (!Array.isArray(reviews.data.data)) {
    reviews.data.data = []
  }

  const totalReviews = reviews.data.total_rating
  const remainingReviews = parseInt(totalReviews) - parseInt(reviews.data.total)

  const ratingCount = allReviews.data.data.reduce(
    (acc, review) => {
      const rating = review.rating.replace(/[^0-9]/g, "") // deixa só os números
      parseInt(rating)
      acc[rating] = (acc[rating] || 0) + 1
      return acc
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  )

  ratingCount[5] = remainingReviews + ratingCount[5]

  const ratingArray = Object.keys(ratingCount).map((rating) => ({
    stars: parseInt(rating),
    count: ratingCount[rating],
  }))

  ratingArray.sort((a, b) => b.stars - a.stars)

  const productsFormatted = {
    ...products.data,
    categorias: products.data.categorias.map((item) => ({
      ...item,
      descricao_categoria: item.descricao_categoria.charAt(0).toUpperCase() + item.descricao_categoria.slice(1),
    })),

    brindes_eventos: products.data.brindes_eventos.map((item) => ({
      ...item,
      codigo_produto: item.codigo_produto,
      prod_nome: item.nome_produto,
      url_prod: item.link_produto,
      img_home_produto: item.imagem_produto,
      codprod: item.codigo_produto,
      caracteristicas: item.descricao_produto,
      preco_home: item.preco_produto,
      estoque: item.estoque_total,
      quantidade_estoque: item.quantidade_reposicao,
      list_cores: item.cores.map((cor) => ({
        hex: cor.rgb_cores,
      })),
    })),

    mais_vendidos: products.data.mais_vendidos.map((item) => ({
      ...item,
      codigo_produto: item.codigo_produto,
      prod_nome: item.nome_produto,
      url_prod: item.link_produto,
      img_home_produto: item.imagem_produto,
      codprod: item.codigo_produto,
      caracteristicas: item.descricao_produto,
      preco_home: item.preco_produto ?? 0,
      estoque: item.estoque_total,
      quantidade_estoque: item.quantidade_reposicao,
      list_cores: item.cores.map((cor) => ({
        hex: cor.rgb_cores,
      })),
    })),
  }

  return {
    reviews: {
      ...reviews.data,
      ratingCount: ratingCount,
      ratingArray: ratingArray,
      remainingReviews: remainingReviews,
    },
    products: productsFormatted,
    faq: faq.data,
    instagramPosts,
  }
}

export async function getStaticProps(context) {
  const linksSubcategorias = await getCategorias()
  const linksSegmentos = await getSegmentos()

  const { reviews, products, faq, instagramPosts } = await getHomeInfo()

  return {
    props: {
      linksSubcategorias: linksSubcategorias.subcategorias,
      linksSegmentos: linksSegmentos.segmentos,
      reviews,
      produtos: products,
      faq,
      instagramPosts: instagramPosts.data,
    }, // will be passed to the page component as props

    revalidate: 60,
  }
}

function generateRichSnippets(posts) {
  return posts.map((post) => ({
    "@context": "https://schema.org",
    "@type": "SocialMediaPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": post.permalink,
    },
    headline: `Post by ${post.username}`,
    articleBody: post.caption?.trim(),
    datePublished: post.timestamp,
    url: post.permalink,
    author: {
      "@type": "Person",
      name: post.username,
      url: post.permalink,
    },
    publisher: {
      "@type": "Organization",
      name: post.username,
      logo: {
        "@type": "ImageObject",
        url: post.thumbnail_url,
      },
    },
    image: post.media_url,
    keywords: post.caption?.match(/#[a-zA-Z0-9]+/g),
  }))
}

export default function Index(props) {
  const richSnippets = generateRichSnippets(props.instagramPosts.data)

  return (
    <>
      <Head>
        <title>Innovation Brindes a maior empresa de Brindes Personalizados Promocionais</title>
        {/* instagram snippets */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(richSnippets) }} />

        {/* Meta tags para Open Graph */}
        <meta property="og:title" content="Innovation Brindes" />
        <meta
          property="og:image"
          content="https://imgproductioncrm.s3.us-east-2.amazonaws.com/copo-home-google-3-3.webp"
        />
        <meta
          property="og:image"
          content="https://imgproductioncrm.s3.us-east-2.amazonaws.com/copo-home-google-3-3.webp"
        />
        <meta property="og:image" content="https://imgprodutos.s3.us-east-2.amazonaws.com/4357/2-2.jpg" />
        <meta property="og:url" content="https://innovationbrindes.com.br" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Innovation Brindes" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="article:author" content="Innovation Brindes" />
        <meta property="site_name" content="Innovation Brindes" />

        {/* Meta tags para Twitter Cards */}
        <meta name="twitter:title" content="Innovation Brindes" />
        <meta name="twitter:image" content="https://imgproductioncrm.s3.us-east-2.amazonaws.com/image.png" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="keywords"
          content="Inicio, Brindes, Brindes Personalizados, Personalizados, Brindes Promocionais, Promocionais, Empresa de Brindes, Brindes Corporativos, Chaveiros Personalizados, Squeeze Personalizado, Canetas Personalizadas, Canetas Para Brindes, Caneta Personalizada, Canetas Promocionais, Produtos Promocionais, Brindes Para Eventos, Carregador Celular Personalizado, Power Bank Personalizado, Espelho Personalizado, Brindes Dia Das Mães, Brindes Dia Dos Pais, Brindes Dia Da Mulher, Brindes Para o Final Do Ano, Brindes Para Funcionários, Confecção Promocional"
        />
        <meta name="robots" content="Index, follow" />
        <meta name="googlebot" content="Index, follow" />
        <meta name="msnbot" content="Index, follow" />
        <meta name="InktomiSlurp" content="Index, follow" />
        <meta name="Unknownrobot" content="Index, follow" />
        <meta name="Revisit-After" content="1" />
        <meta name="Publisher" content="Innovation Brindes a maior empresa de Brindes Personalizados Promocionais" />
        <meta name="Designer" content="Innovation Brindes a maior empresa de Brindes Personalizados Promocionais" />
        <meta
          name="Abstract"
          content="Brindes | Brinde | Brindes Baratos | Brindes Promocionais | Brindes Personalizados | Brinde Promocional | Empresa de Brindes | Comprar Brindes | Brindes Empresas"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="shareaholic:site_name" content="Innovation Brindes" />
        <meta name="shareaholic:language" content="pt-BR" />
        <meta name="google-site-verification" content="wBWtim4iA3_C8qp-dr_Gyv47Vf0SoEr0b_SpAaPKY" />
        <meta name="msvalidate.01" content="7D236B682E6D1FAB048CBF1C65790B63" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:url" content="https://innovationbrindes.com.br/" />
        <meta property="og:title" content="Innovation Brindes a maior empresa de Brindes Personalizados Promocionais" />

        <meta property="og:image" content="https://imgprodutos.s3.us-east-2.amazonaws.com/4300/2-2.jpg" />
        <meta property="og:image" content="https://imgprodutos.s3.us-east-2.amazonaws.com/5081/3-3.jpg" />
        <meta property="og:image" content="https://imgprodutos.s3.us-east-2.amazonaws.com/4357/2-2.jpg" />
        <meta
          property="og:image"
          content="https://imgprodutos.s3.us-east-2.amazonaws.com/0334/caneta-ecologica-brindes-personalizados-promocional-para-eventos-corporativos-innovation-2-2.jpg"
        />
        <meta property="og:site_name" content="Innovation Brindes" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pt_BR" />

        <meta
          itemprop="image"
          content="https://imgproductioncrm.s3.us-east-2.amazonaws.com/copo-home-google-3-3.webp"
        />
      </Head>
      <Header subcategorias={props.linksSubcategorias} segmentos={props.linksSegmentos} />
      <Home
        produtos={props.produtos}
        categorias={props.categorias}
        subcategorias={props.subcategorias}
        textoSeo={props.textoSeo}
        reviews={props.reviews}
        faq={props.faq}
        {...props}
      />
      <AcceptCookies />
    </>
  )
}
