import { ReviewComponent } from "../../ReviewComponent"
import { StarReviews } from "../../StarReviews"
import { HeadingSection } from "../HeadingSection"

export function Reviews({ reviews }) {
  return (
    <section className="flex flex-col gap-6">
      <HeadingSection text="O que os nossos clientes dizem" />

      <div className="w-full md:h-[195px] bg-[#F5F5F5] shadow-md border p-6 flex justify-between gap-6 items-center flex-col md:flex-row">
        <div className="max-w-[622px] w-full flex flex-col gap-6">
          <span className="text-xl">Na Innovation Brindes, a satisfação do cliente é nossa prioridade.</span>
          <span>
            Somos a empresa de brindes personalizados{" "}
            <span className="font-bold">com o maior número de avaliações e a maior nota do mercado.</span> Veja o que
            nossos clientes têm a dizer sobre nossos produtos e serviços.
          </span>
        </div>
        <div className="flex items-center gap-2 w-full max-w-[600px] flex-col md:flex-row">
          <div className="flex flex-col gap-2">
            <span className="font-bold">Resumo de comentários do Google</span>
            <div className="flex flex-col w-[357px]">
              {reviews?.ratingArray?.map((rating, index) => (
                <ReviewComponent.Root key={index} index={index}>
                  <ReviewComponent.Quantity>{rating.stars}</ReviewComponent.Quantity>
                  <ReviewComponent.Slider quantity={rating.count} total={reviews.total_rating} />
                </ReviewComponent.Root>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-center w-full flex-col gap-2">
            <span className="text-6xl font-bold">{reviews.rating}</span>
            <div className="flex items-center gap-2">
              <StarReviews rating={reviews.total_rating} />
            </div>

            <span className="font-medium text-sm">{reviews.total_rating} comentários</span>
          </div>
        </div>
      </div>
    </section>
  )
}
