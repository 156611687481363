import { FaStar } from "react-icons/fa"
import classNames from "classnames"

export function StarReviews({ rating, starClassName, ...rest }) {
  return (
    <div className={classNames("flex items-center gap-1")}>
      {Array.from({ length: 5 }).map((_, index) => (
        <FaStar
          key={index}
          className={classNames(rest.className, "text-2xl", {
            "text-yellow-400": index < rating,
            "text-gray-300": index >= rating,
          })}
        />
      ))}
    </div>
  )
}
