import Image from "next/image"
import { format } from "date-fns"
import { AiFillInstagram } from "react-icons/ai"
import { FaHeart, FaPlay, FaRegComment } from "react-icons/fa"
import ReactPlayer from "react-player"

export function CardInstagram() {}

CardInstagram.Root = function CardInstagramRoot({ children }) {
  return <article className="max-w-[285px] w-full border shadow-md p-2 space-y-2 mx-auto bg-white">{children}</article>
}

CardInstagram.Header = function CardInstagramHeader({ username, timestamp }) {
  return (
    <header className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Image
          src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/logo%20mobile-01.svg"
          alt={username}
          width={32}
          height={32}
        />
        <div className="flex flex-col">
          <h3 className="text-sm text-[#414042] font-bold">{username}</h3>
          <time className="text-[9px] text-[#414042] font-light" datetime={timestamp}>
            {format(new Date(timestamp), "dd/MM/yyyy 'às' HH:mm")}
          </time>
        </div>
      </div>
      <AiFillInstagram size={24} color="#414042" />
    </header>
  )
}

CardInstagram.VerMais = function CardInstagramVerMais({ link }) {
  return (
    <a target="_blank" rel="noreferrer" href={link}>
      <button className="w-full bg-[#414042] text-white py-1 text-xs hover:brightness-75">Ver no instagram</button>
    </a>
  )
}

CardInstagram.Video = function CardInstagramVideo({ src, thumbnail_url }) {
  return (
    <ReactPlayer
      url={src}
      controls
      width="100%"
      height="270px"
      light={<Image src={thumbnail_url} alt="Thumbnail do vídeo" width={271} height={270} objectFit="cover" />}
      playing
      playIcon={
        <div className="min-h-[270px] flex items-center justify-center absolute">
          <button
            aria-label="Play video"
            className="animate-scale-110 w-12 h-12 bg-[#EF511B] rounded-full flex items-center justify-center border-2 border-white"
          >
            <FaPlay className="text-white text-xl" />
          </button>
        </div>
      }
    />
  )
}

CardInstagram.Likes = function CardInstagramLikes({ likes }) {
  return (
    <div className="flex items-center gap-2">
      <FaHeart size={22} color="#414042" />
      <span className="text-[12px] text-[#414042]">{likes}</span>
    </div>
  )
}

CardInstagram.Comments = function CardInstagramComments({ comments }) {
  return (
    <div className="flex items-center gap-2">
      <FaRegComment size={22} color="#414042" />
      <span className="text-[12px] text-[#414042]">{comments}</span>
    </div>
  )
}

CardInstagram.Image = function CardInstagramContent({ caption, thumbnail_url, media_url }) {
  return <Image src={thumbnail_url || media_url} alt={caption} width={271} height={270} objectFit="cover" />
}

CardInstagram.Content = function CardInstagramContent({ caption }) {
  return (
    <figcaption className="line-clamp-6 text-[12px] text-[#414042]" dangerouslySetInnerHTML={{ __html: caption }} />
  )
}
