import Link from "next/link"
import { Video } from "../../Product/Impressao360/components/Video"
import { FaPlay } from "react-icons/fa"
import Image from "next/image"
import React from "react"

export function VideoExclusivo({ mobile }) {
  const [isPlayingVideo, setIsPlayingVideo] = React.useState(false)

  return (
    <div className="flex items-center">
      {!isPlayingVideo && (
        <div className="flex items-center justify-center md:block max-w-[616px] w-full h-full md:h-[388px] relative">
          <Image
            src={
              !mobile
                ? "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20126%402x.png1721738376.9634"
                : "https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%20205%402x.png1722270296.1006"
            }
            alt="Retire seu brinde personalizado no mesmo dia"
            width={!mobile ? 616 : 364}
            height={!mobile ? 388 : 578}
            objectFit="cover"
          />

          <div className="max-w-[276px] h-[232px] justify-between w-full flex flex-col gap-1 absolute bottom-3  md:bottom-6 md:left-6 bg-white/80 py-2 px-4 rounded-md">
            <span className="text-xl font-bold">
              Nova Personalização Exclusiva: <br /> Impressão UV 360º
            </span>
            <span className="text-sm font-medium leading-4">
              Cores vibrantes e detalhes impressionantes que envolvem todo o produto, realçados por um acabamento
              premium em verniz para um brilho excepcional.
            </span>

            <button
              aria-label="De o play e saiba mais"
              className="font-medium flex items-center justify-between rounded-rounded-button-play overflow-hidden  bg-[#F45100] text-center text-white cursor-pointer"
              onClick={() => setIsPlayingVideo(true)}
            >
              <span
                className="px-4 rounded-xl bg-[#F45100] text-center text-white cursor-pointer"
                aria-label="De o play e saiba mais"
              >
                Dê o play e saiba mais{" "}
              </span>
              <div className="animate-scale-110 ml-1 w-[39px] h-[39px] rounded-full border-2 border-white flex items-center justify-center">
                <FaPlay className="text-white text-xl" />
              </div>
            </button>
          </div>
        </div>
      )}

      {isPlayingVideo && (
        <div className="w-full bg-gray-50 relative md:h-[388px] flex items-center justify-center">
          <Video
            onEnded={() => setIsPlayingVideo(false)}
            controls
            muted
            playing
            url="https://www.youtube.com/watch?v=myGWHbYnsNk"
          />
        </div>
      )}
    </div>
  )
}
