import { DepoimentosDestaque } from "./DepoimentosDestaque"
import { ExploreBlog } from "./ExploreBlog"
import { FacilidadeVantagens } from "./FacilidadeVantagens"
import { Personalizado5Passos } from "./Personalizado5Passos"
import { PorQueEscolher } from "./PorQueEscolher"
import { Reviews } from "./Reviews"
import { Sugestoes } from "./Sugestoes"
import dynamic from "next/dynamic"
import { VoceSoEncontraAqui } from "./VoceSoEncontraAqui"
import { SaibaMais } from "./SaibaMais"
import { PersonalizacaoExpressaExclusivo } from "./PersonalizacaoExpressaExclusivo "
import { OndeEstamos } from "./OndeEstamos"
import { InstagramPosts } from "./InstagramPosts"

const NossasCategorias = dynamic(() => import("./NossasCategorias").then((mod) => mod.NossasCategorias))
const BrindesParaEventos = dynamic(() => import("./BrindesParaEventos").then((mod) => mod.BrindesParaEventos))
const BrindesPromocionais = dynamic(() => import("./BrindesPromocionais").then((mod) => mod.BrindesPromocionais))

export const Sections = {
  PorQueEscolher,
  NossasCategorias,
  PersonalizacaoExpressaExclusivo,
  BrindesParaEventos,
  FacilidadeVantagens,
  Sugestoes,
  BrindesPromocionais,
  ExploreBlog,
  Reviews,
  DepoimentosDestaque,
  Personalizado5Passos,
  VoceSoEncontraAqui,
  SaibaMais,
  OndeEstamos,
  InstagramPosts,
}
