import Image from "next/image"
import { HeadingSection } from "../HeadingSection"
import Link from "next/link"
import { useMediaQuery } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export function ExploreBlog() {
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  return (
    <section className="flex justify-between flex-col md:flex-row gap-10 md:gap-4">
      <div className="flex flex-col gap-6 flex-1 max-w-[616px] w-full">
        <HeadingSection text="EXPLORE NOSSO BLOG DE BRINDES PERSONALIZADOS" />

        <div className="flex items-center justify-between flex-row flex-wrap">
          <div className="md:h-[388px] md:shadow-md relative md:border hover:-translate-y-1 hover:shadow-xl transition">
            <Image
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/Mask%20Group%2037%402x.png1721738699.7469"
              alt="Retire seu brinde personalizado no mesmo dia"
              width={616}
              height={388}
              objectFit="cover"
            />

            {!mobile && (
              <div className="max-w-[276px] h-[232px] justify-between w-full flex flex-col gap-7 absolute bottom-6 left-6 bg-white/80 py-2 px-4 rounded-md">
                <span className="text-2xl font-bold">Explore Nosso Blog</span>
                <span className="text-sm font-medium">
                  Descubra insights e tendências sobre brindes personalizados. Aumente a relevância da sua marca!
                </span>

                <Link href="https://blog.innovationbrindes.com.br">
                  <span className="font-medium py-2 px-16 rounded-xl bg-[#F45100] text-center text-white cursor-pointer">
                    Leia Mais
                  </span>
                </Link>
              </div>
            )}

            {mobile && (
              <div className="flex flex-col gap-4 items-center">
                <span className="text-sm">
                  Descubra insights e tendências sobre brindes personalizados. Aumente a relevância da sua marca!
                </span>

                <Link href={`https://blog.innovationbrindes.com.br`}>
                  <span className="max-w-[200px] w-full bg-[#F45100] py-2 flex items-center justify-center text-white rounded-md">
                    Leia mais
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 flex-1 max-w-[616px] w-full ">
        <HeadingSection text="LANÇAMENTOS" />

        <div className="flex items-center justify-between flex-row flex-wrap">
          <div className="md:h-[388px] w-full flex items-center justify-center md:shadow-md relative md:border hover:-translate-y-1 md:hover:shadow-xl transition">
            <Image
              src={
                !mobile
                  ? "https://imgproductioncrm.s3.us-east-2.amazonaws.com/novidades-desk.jpg1722281617.0384"
                  : "https://imgproductioncrm.s3.us-east-2.amazonaws.com/novidades-mobile.jpg1722281617.111"
              }
              alt="Retire seu brinde personalizado no mesmo dia"
              width={!mobile ? 616 : 400}
              height={!mobile ? 388 : 578}
              objectFit="cover"
            />

            <div className="max-w-[276px] h-[232px] w-full flex justify-between flex-col gap-2 py-2 absolute bottom-6 md:left-6 left-1/2 -translate-x-1/2 md:translate-x-0 bg-white/80 px-4 rounded-md">
              <span className="text-xl font-bold leading-7">
                Descubra os últimos lançamentos em Brindes Personalizados.
              </span>
              <span className="text-sm font-medium">Surpreenda seus clientes com novidades exclusivas!</span>

              <Link href="/segmento/novidades/6">
                <span className="font-medium py-2 px-16 rounded-xl bg-[#F45100] text-center text-white cursor-pointer">
                  Veja Mais
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
